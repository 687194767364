:root {
  --black: #222;
  --lwhite: #d0dde7;
  --white: #fff;
  --dwhite: #fefefe;
  --lblue-hover: #1f9cfe;
  --lblue: #1f75fe;
  --blue: #1d428a;
  --dblue: #041e42;
  --lgray: #f6f8fb;
  --gray: #8897a5;
  --gray-pill: #dfe4e9;
  --gray-pill-opacity: rgba(223, 228, 233, 0.4);
  --pink: #fd5c63;
  --red: #e32636;
  --green: #90ee90;
  --dgreen: #27b67d;
  --shadow: 0 0 50px 0 rgba(29, 66, 138, 0.15);
  --animate-delay: 0.5s;
}

body,
html {
  background: var(--dwhite);
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: var(--black);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Custom */
.container {
  box-sizing: border-box;
  max-width: 1127px;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .container {
    padding: 20px;
  }
}

.values-ul {
  display: list-item;
  list-style: disc;
}

strong {
  font-weight: 700;
}
